export default function HomeEmptyPage() {
    
    const onClick = () => {
        console.log('clicked');
    }

    
    return (
        <button 
            className="flex border h-[300px] w-full flex-col items-center justify-center rounded-xl hover:bg-buttonSecondary-bgHover"
            onClick={onClick}
        >
            Create project
        </button>
    );
}
function cuid(): string {
    throw new Error("Function not implemented.");
}

