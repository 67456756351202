"use client";

import React, { createContext, useContext, type ReactNode } from 'react';
import enTranslations from './locales/en.json';
import itTranslations from './locales/it.json';

export enum Language {
  English = 'en',
  Italian = 'it',
}

export const translations: { [key in Language]: { [key: string]: string } } = {
  en: enTranslations,
  it: itTranslations,
};

interface LanguageContextProps {
  userlanguage: Language;
  setUserLanguage: (language: Language) => void;
  getTranslation: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userLanguage, setUserLanguage] = React.useState<Language>(Language.English);

  // useEffect(() => {
  //   if (user?.language) {
  //     setLanguage(user.language);
  //   } else {
  //     // Fallback to default languages if user settings are not available
  //     setLanguage(defaultLanguage);
  //   }
  // }, [user]);
  

  const getTranslation = (key: string) => {
    return translations[userLanguage][key] || key;
  };
  
  const contextValue: LanguageContextProps = {
    userlanguage: userLanguage,
    setUserLanguage: setUserLanguage,
    getTranslation: getTranslation,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
