import { fetcher } from "@/lib/utils/functions";
import { Project } from "@prisma/client";
import useSWR from "swr";

export default function useProjects() {
  const {data: projects, error} = useSWR<Project[]>(
    '/api/projects',
    fetcher,
  );

  return {
    projects: projects,
    isLoading: !projects && !error,
    isError: error,
  }
}