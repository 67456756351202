"use client";

import { PageContent } from "@/ui/layout/page-content";
import { MaxWidthWrapper } from "@/ui/components";
import HomeEmptyPage from "@/ui/pages/home/home-empty-page";
import Projects from "@/ui/pages/home/projects";

import { useLanguage } from "@/lib/languages/language-provider";
import useProjects from "@/lib/swr/use-projects";

export default function Home() {
  const { projects } = useProjects();
  const { getTranslation } = useLanguage();

  return projects && (
    <PageContent title = {getTranslation('word-home')}>
      <MaxWidthWrapper className="pt-3">
        { projects.length > 0 ? (
          <Projects projects={projects} />
        ) : (
          <HomeEmptyPage />
        )}
      </MaxWidthWrapper>
    </PageContent>
  );
}
