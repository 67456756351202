import { Project } from "@prisma/client";
import { useRouter } from "next/navigation";

interface ProjectsProps {
    projects: Project[];
}

export default function Projects({ projects }: ProjectsProps) {
    const router = useRouter();

    return (
        <div className="flex flex-wrap gap-4">
            <button className="w-full max-w-[300px] border p-4 rounded-sm h-[400px]      
                bg-bg-tertiary flex items-center justify-center">
                <h2 className="text-xl">Create a new project</h2>
            </button>
            {projects.map((project) => (
                <button 
                    key={project.id}
                    className="w-full max-w-[300px] border p-4 rounded-sm h-[400px] hover:bg-bg-secondary cursor-pointer"
                    onClick={() => router.push(`/editor/${project.id}`)}
                >
                    <h2 className="text-xl">{project.title}</h2>
                </button>
            ))}
        </div>
    );
}
