"use client";

import { Button } from "@/ui/components";
import { useContext } from "react";
import { SideNavContext } from "../main-nav";
import { LayoutSidebar } from "@/ui/icons";

export function NavButton() {
  const { setIsOpen } = useContext(SideNavContext);

  return (
    <Button
      onClick={() => setIsOpen((o) => !o)}
      icon={<LayoutSidebar className="size-4" />}
      className="h-auto w-fit p-1 md:hidden"
    />
  );
}
